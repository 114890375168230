<template>
	<div id="Activity">
		<div class="Activity_list">
			<div class="Activity_item" v-for="(item,index) in WelfareList" :key="index" @click="ToOpen(item.type,item.box_id,item.id)">
				<div class="Activity_tit">{{item.type_alias}}</div>
				<div class="Activity_pic">
					<img :src="item.box.intact_cover" alt="">
				</div>
				<p>{{item.name}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { Welfare } from '@/network/api.js'
	export default{
		name:'Activity',
		data(){
			return{
				WelfareList:[]//福利箱列表
			}
		},
		created() {
			this.GetWelfare()
		},
		methods:{
			//福利箱列表
			GetWelfare(){
				Welfare().then((res) => {
					console.log(res.data.data)
					this.WelfareList = res.data.data
				})
			},

			//路由跳转开箱
			ToOpen(type,activityid,id){
				this.$router.push({
					path:'/Activity_open',
					query:{
						type:type,
						activityid:activityid,
						id:id
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	#Activity{
		width: 100%;
		padding: 20px 10px 0px;
		box-sizing: border-box;
		.Activity_list{
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.Activity_item{
				width: 48%;
				margin-bottom: 20px;
				padding: 10px 0;
				text-align: center;
				border-radius: 4px;
				.Activity_tit{
					width: 100%;
					text-align: center;
					font-size: 16px;
					color: #fff;
				}
				.Activity_pic{
					width: 100%;
					height: 100px;
					display: flex;
					margin: 0 auto;
					justify-content: center;
					align-items: center;
					img{
						max-width: 100%;
						max-height: 100%;
					}
				}
				p{
					display: inline-block;
					margin-top: 10px;
					padding: 0 10px;
					height: 26px;
					border: 1px solid #ffba2e;
					/* background-image: linear-gradient(#ffcf00,#ffb500);*/
					/*border-radius: 12px;*/
					color: #fff;
					line-height: 27px;

				}
			}
		}
	}
</style>
